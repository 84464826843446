import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { Link } from 'gatsby';
import BrandSVGDark from '../../shared/components/svg/brand-svg-dark';
import './footer.scss';

const footerItems = {
  en: [
    { href: '/contact/', name: 'Contact', highlight: true, gatsbyLink: true },
    { href: '/careers/', name: 'Career opportunities', highlight: false, gatsbyLink: true },
    { href: 'https://blog.sherpa.ai/', name: 'Blog', highlight: false, gatsbyLink: false },
    { href: 'https://developers.sherpa.ai/', name: 'Developers', highlight: false, gatsbyLink: false },
    { href: '/privacy-policy/', name: 'Privacy Policy', highlight: false, gatsbyLink: true },
    { href: '/politica-de-cookies/', name: 'Cookie Policy', highlight: false, gatsbyLink: true },
  ],
  es: [
    { href: '/es/contacto/', name: 'Contacto', highlight: true, gatsbyLink: true },
    { href: '/es/buscamos-talento/', name: 'Oportunidades laborales', highlight: false, gatsbyLink: true },
    { href: 'https://blog.sherpa.ai/', name: 'Blog', highlight: false, gatsbyLink: false },
    { href: 'https://developers.sherpa.ai/', name: 'Developers', highlight: false, gatsbyLink: false },
    { href: '/es/politica-de-privacidad/', name: 'Política de privacidad', highlight: false, gatsbyLink: true },
    { href: '/es/politica-de-cookies/', name: 'Política de cookies', highlight: false, gatsbyLink: true },
  ],
  eu: [
    { href: '/eu/contact/', name: 'Kontaktua', highlight: true, gatsbyLink: true },
    { href: '/eu/careers/', name: 'Lan eskaintzak', highlight: false, gatsbyLink: true },
    { href: 'https://blog.sherpa.ai/', name: 'Blog', highlight: false, gatsbyLink: false },
    { href: 'https://developers.sherpa.ai/', name: 'Developers', highlight: false, gatsbyLink: false },
    { href: '/eu/privacy-policy/', name: 'Pribatasun politika', highlight: false, gatsbyLink: true },
    { href: '/eu/politica-de-cookies/', name: 'Cookie politika', highlight: false, gatsbyLink: true },
  ],
};

const Footer = ({ currentLang }) => {
  const hostRef = createRef();

  return (
    <footer className='site-footer' ref={hostRef}>
      <div className='site-footer-inner wrapper-fluid'>
        <a href='/' className='site-footer-brand'>
          <BrandSVGDark />
        </a>
        <nav className='nav-legal'>
          <ul>
            {footerItems[currentLang].map(item => {
              return (
                <li key={`legal_${uuid()}`}>
                  {item.gatsbyLink && (
                    <Link to={item.href}>
                      {item.highlight && <strong>{item.name}</strong>}
                      {!item.highlight && item.name}
                    </Link>
                  )}
                  {!item.gatsbyLink && (
                    <a href={item.href} target='_blank' rel='noreferrer'>
                      {item.highlight && <strong>{item.name}</strong>}
                      {!item.highlight && item.name}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  currentLang: PropTypes.string.isRequired,
};

export default Footer;
