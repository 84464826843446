module.exports = [
  {
    id: 'home',
    slugs: {
      es: '/',
      en: '/',
      eu: '/',
    },
    template: 'modules/home/templates/home.jsx',
  },
  {
    id: 'solutions',
    slugs: {
      es: '/tecnologias-privacidad-esg/',
      en: '/esg-privacy-technologies/',
      eu: '/esg-privacy-technologies/',
    },
    template: 'modules/solutions/templates/solutions.jsx',
  },
  {
    id: 'use-cases-prisa',
    slugs: {
      es: '/casos-exito/prisa/',
      en: '/use-cases/prisa/',
      eu: '/use-cases/prisa/',
    },
    template: 'modules/use-cases/prisa/templates/prisa.jsx',
  },
  {
    id: 'use-cases-orange-and-zurich',
    slugs: {
      es: '/casos-exito/inteligencia-artificial-orange-zurich/',
      en: '/use-cases/artificial-intelligence-orange-zurich/',
      eu: '/use-cases/artificial-intelligence-orange-zurich/',
    },
    template: 'modules/use-cases/orange-and-zurich/templates/orange-and-zurich.jsx',
  },
  {
    id: 'use-cases-telefonica',
    slugs: {
      es: '/casos-exito/inteligencia-artificial-telefonica/',
      en: '/use-cases/artificial-intelligence-telefonica/',
      eu: '/use-cases/artificial-intelligence-telefonica/',
    },
    template: 'modules/use-cases/telefonica/templates/telefonica.jsx',
  },
  {
    id: 'use-cases-nih',
    slugs: {
      es: '/casos-exito/inteligencia-artificial-nih/',
      en: '/use-cases/artificial-intelligence-nih/',
      eu: '/use-cases/artificial-intelligence-nih/',
    },
    template: 'modules/use-cases/nih/templates/nih.jsx',
  },
  {
    id: 'use-cases-prosegur',
    slugs: {
      es: '/casos-exito/inteligencia-artificial-prosegur/',
      en: '/use-cases/artificial-intelligence-prosegur/',
      eu: '/use-cases/artificial-intelligence-prosegur/',
    },
    template: 'modules/use-cases/prosegur/templates/prosegur.jsx',
  },
  {
    id: 'technology',
    slugs: {
      es: '/nuestro-aprendizaje-federado/',
      en: '/our-federated-learning/',
      eu: '/our-federated-learning/',
    },
    template: 'modules/technology/templates/technology.jsx',
  },
  {
    id: 'platform',
    slugs: {
      es: '/plataforma-aprendizaje-federado/',
      en: '/federated-learning-platform/',
      eu: '/federated-learning-platform/',
    },
    template: 'modules/platform/templates/platform.jsx',
  },
  {
    id: 'covid19',
    slugs: {
      es: '/casos-exito/inteligencia-artificial-covid19/',
      en: '/use-cases/artificial-intelligence-covid19/',
      eu: '/use-cases/artificial-intelligence-covid19/',
    },
    template: 'modules/covid19/templates/covid19.jsx',
  },
  {
    id: 'about',
    slugs: {
      es: '/sobre-nosotros/',
      en: '/about/',
      eu: '/about/',
    },
    template: 'modules/about/templates/about.jsx',
  },
  {
    id: 'news',
    slugs: {
      es: '/noticias/',
      en: '/news/',
      eu: '/news/',
    },
    template: 'modules/news/templates/news.jsx',
  },
  {
    id: 'contact',
    slugs: {
      es: '/contacto/',
      en: '/contact/',
      eu: '/contact/',
    },
    template: 'modules/contact/templates/contact.jsx',
  },
  {
    id: 'join-us',
    slugs: {
      es: '/buscamos-talento/',
      en: '/careers/',
      eu: '/careers/',
    },
    template: 'modules/join-us/templates/join-us.jsx',
  },
  {
    id: 'privacy-policy',
    slugs: {
      es: '/politica-de-privacidad/',
      en: '/privacy-policy/',
      eu: '/privacy-policy/',
    },
    template: 'modules/legal/templates/legal.jsx',
  },
  {
    id: 'cookie-policy',
    slugs: {
      es: '/politica-de-cookies/',
      en: '/politica-de-cookies/',
      eu: '/politica-de-cookies/',
    },
    template: 'modules/legal/templates/legal.jsx',
  },
  {
    id: 'protection-applicant-data',
    slugs: {
      es: '/proteccion-de-datos/',
      en: '/proteccion-de-datos/',
      eu: '/proteccion-de-datos/',
    },
    template: 'modules/legal/templates/legal.jsx',
  },
  {
    id: 'esg',
    slugs: {
      es: '/cumplimiento-normativa-esg/',
      en: '/esg-compliance/',
      eu: '/esg-compliance/',
    },
    template: 'modules/esg/templates/esg.jsx',
  },
];
