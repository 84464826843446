import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LanguagesSelector from '../languages-selector/languages-selector';
import NavMain from '../nav-main/nav-main';
import './menu.scss';

const Menu = ({ open, currentRoute, currentLang }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [open]);

  const classOpen = open ? 'open' : '';

  return (
    <div className={`site-menu ${classOpen}`}>
      <div className='wrapper-fluid'>
        <NavMain currentLang={currentLang} />
        <LanguagesSelector currentLang={currentLang} currentRoute={currentRoute} />
      </div>
    </div>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  currentLang: PropTypes.string.isRequired,
  currentRoute: PropTypes.shape({
    slugs: PropTypes.shape({
      es: PropTypes.string,
      en: PropTypes.string,
      eu: PropTypes.string,
    }),
  }).isRequired,
};

Menu.displayName = 'Menu';
export default Menu;
