import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import BrandSVG from '../../shared/components/svg/brand-svg';
import HamburgerMenu from '../../shared/components/svg/hamburger-menu';
import NavMain from '../nav-main/nav-main';
import LanguagesSelector from '../languages-selector/languages-selector';
import CloseMenu from '../../shared/components/svg/close-menu';

import './header.scss';

// eslint-disable-next-line no-unused-vars
const Header = forwardRef(({ menuOpened = false, currentRoute, currentLang, onButtonMenuClicked }, _ref) => {
  const [firstRender, setFirstRender] = useState(true);

  const handleButtonMenuClick = () => {
    onButtonMenuClicked();
  };

  useEffect(() => {
    setFirstRender(false);
  }, [firstRender]);

  const hostClass = menuOpened ? 'site-header menu-open' : 'site-header';

  return (
    <header className={hostClass}>
      <div className='site-header-inner'>
        <div className='wrapper-fluid'>
          <Link
            className='brand'
            state={{ homeInnerNavigation: true }}
            to={`/${currentLang !== 'en' ? `${currentLang}/` : ''}`}
          >
            <BrandSVG />
          </Link>
          <NavMain showOnlyInDesktop currentLang={currentLang} />
          <LanguagesSelector currentLang={currentLang} currentRoute={currentRoute} showOnlyInDesktop />
          <button onClick={handleButtonMenuClick} className='button-menu hidden-navbar-up' type='button'>
            <HamburgerMenu />
            <CloseMenu />
          </button>
        </div>
      </div>
    </header>
  );
});
Header.propTypes = {
  menuOpened: PropTypes.bool.isRequired,
  currentLang: PropTypes.string,
  currentRoute: PropTypes.shape({
    slugs: PropTypes.shape({
      es: PropTypes.string,
      en: PropTypes.string,
      eu: PropTypes.string,
    }),
  }),
  onButtonMenuClicked: PropTypes.func,
};

Header.displayName = 'Header';

export default Header;
