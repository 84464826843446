import routes from '../../../routes';

const getRoute = page => {
  const getRoutes = routes.find(route => {
    return route.id === page;
  });
  return getRoutes;
};

export default getRoute;
