/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import BackIcon from '../svg/back-icon';
import CookieConsentTable from './cookie-consent-table';
import './cookie-consent.scss';

const CookieConsent = ({ content }) => {
  const barRef = useRef(null);
  const detailRef = useRef(null);

  const initialTerms = {
    navigationCookies: true,
    thirdPartCookies: false,
  };

  const [state, _setState] = useState(null); // hidden | bar | detail
  const stateRef = React.useRef(state);
  const setState = data => {
    stateRef.current = data;
    _setState(data);
  };
  const [terms, setTerms] = useState(initialTerms);
  const [height, setHeight] = useState('auto');

  const setWrapperHeight = () => {
    if (barRef.current && detailRef.current) {
      if (stateRef.current === 'bar') {
        const barHeight = barRef.current.getBoundingClientRect().height;
        setHeight(`${barHeight}px`);
      } else if (stateRef.current === 'detail') {
        const detailHeight = detailRef.current.getBoundingClientRect().height;
        setHeight(`${detailHeight}px`);
      }
    }
  };

  const acceptAll = () => {
    Cookies.set('sp_ck_consent', JSON.stringify(terms), {
      expires: 365,
      sameSite: 'strict',
    });
    window[`ga-disable-${process.env.GATSBY_SHERPA_UA_CODE}`] = false;
    window.ga('create', `${process.env.GATSBY_SHERPA_UA_CODE}`, 'auto'); // Creates a tracker.
    window.ga('send', 'pageview', window.location.pathname); // Sends a pageview.
    setState('hidden');
  };

  const configure = () => {
    barRef.current.style.display = 'none';
    detailRef.current.style.display = '';
    setState('detail');
    setWrapperHeight('detail');
  };

  const back = () => {
    detailRef.current.style.display = 'none';
    barRef.current.style.display = '';
    setState('bar');
    setWrapperHeight('bar');
  };

  const acceptAndSave = () => {
    if (terms.thirdPartCookies) {
      acceptAll();
    }
    Cookies.set('sp_ck_consent', JSON.stringify(terms), {
      expires: 365,
      sameSite: 'strict',
    });
    setState('hidden');
  };

  useEffect(() => {
    if (!state) {
      const spCookie = Cookies.get('sp_ck_consent');
      const consent = spCookie ? JSON.parse(spCookie) : spCookie;
      if (consent) {
        setState('hidden');
      } else {
        setState('bar');
      }
    }
    if (height === 'auto' && barRef.current && state === 'bar') {
      detailRef.current.style.display = 'none';
      setWrapperHeight();
      window.addEventListener('resize', () => {
        setWrapperHeight();
      });
    }
    return window.removeEventListener('resize', () => {});
  });

  useEffect(() => {
    if (!state ?? state === 'hidden') {
      document.body.style.overflow = 'visible';
    }
  }, [state]);

  if (!state || state === 'hidden') return null;
  return (
    <>
      <div className='cookie-consent-backdrop' />
      <div className={`cookie-consent-wrapper ${state}`} style={{ height }}>
        <div className='cookie-consent-bar' ref={barRef}>
          <p className='title h3'>{content.title}</p>
          <p className='description' dangerouslySetInnerHTML={{ __html: content.description }} />
          <CookieConsentTable
            content={content.cookiesTypes}
            fields={content.fields}
            moreInfo={content.moreInfo}
            terms={terms}
            setTerms={setTerms}
            configure={configure}
          />
          <div className='actions-wrapper'>
            <button
              className='button button--tertiary'
              onClick={() => {
                configure();
              }}
            >
              {content.actions.moreInfo}
            </button>
            <div className='accept-buttons-wrapper'>
              <button
                className='button button--primary-outline'
                onClick={() => {
                  acceptAndSave();
                }}
              >
                {content.actions.acceptSelection}
              </button>
              <button
                className='button button--primary link-accept'
                onClick={() => {
                  acceptAll();
                }}
              >
                {content.actions.acceptAll}
              </button>
            </div>
          </div>
        </div>
        <div className='cookie-consent-detail' ref={detailRef}>
          <button
            className='button button--icon'
            onClick={() => {
              back();
            }}
            aria-label='Go back'
          >
            <BackIcon />
          </button>
          <header>
            <p className='title h3'>{content.title}</p>
          </header>
          <CookieConsentTable
            content={content.cookiesTypes}
            fields={content.fields}
            terms={terms}
            setTerms={setTerms}
            expanded
          />
          <div className='actions-wrapper'>
            <div className='accept-buttons-wrapper'>
              <button
                className='button button--primary-outline'
                onClick={() => {
                  acceptAndSave();
                }}
              >
                {content.actions.acceptSelection}
              </button>
              <button
                className='button button--primary link-accept'
                onClick={() => {
                  acceptAll();
                }}
              >
                {content.actions.acceptAll}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;
