import React, { useState } from 'react';
import uuid from 'react-uuid';
import GatsbyLink from '../../../layout/gatsby-link/GatsbyLink';
import ChevronDown from '../svg/chevron-down';
import './dropdown-nav-main.scss';

function DropdownNavMain({ name, menuItems = [] }) {
  const [navState, setNavState] = useState({ open: false });

  const activateSelector = () => {
    setNavState({ ...navState, open: !navState.open });
  };

  const dropdownRender = (
    <nav className='dropdown--navbar'>
      <ul>
        {menuItems.map(item => {
          const { highlight } = item;
          return (
            <li key={`nav_${uuid()}`} className={highlight ? 'highlighted' : ''}>
              <GatsbyLink item={item} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
  let isHighlighted = false;

  if (typeof window !== 'undefined') {
    const currentPathname = window.location.pathname;
    isHighlighted = menuItems.some(({ href }) => [href, `${href}/`].includes(currentPathname));
  }

  return (
    <div className={`dropdown${navState.open ? ' dropdown--open' : ''}`}>
      <button
        type='button'
        className={`${isHighlighted && 'highlighted'} button button--navbar`}
        onClick={activateSelector}
      >
        <span className='text'>{name}</span>
        <span className='icon'>
          <ChevronDown />
        </span>
      </button>
      {dropdownRender}
    </div>
  );
}

export default DropdownNavMain;
