import React from 'react';
import { createRoot } from 'react-dom/client';
import Proptypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import NotFoundPage from './src/pages/404';

/* problem with the component */
const ErrorFallback = ({ error }) => {
  console.error(error);
  return <NotFoundPage />;
};
ErrorFallback.propType = {
  error: Proptypes.string.isRequired,
};

const ErrorBoundaryContainer = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};

ErrorBoundaryContainer.propTypes = {
  children: Proptypes.node.isRequired,
};

export const onClientEntry = () => {
  const calculateViewportHeight = () => {
    document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
  };

  window.onload = () => {
    // window.addEventListener('resize', () => calculateViewportHeight())
    calculateViewportHeight();
  };
};

export const shouldUpdateScroll = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  return [0, 0];
};

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

// Wraps every page in a component
export const wrapPageElement = ({ element }) => {
  return <ErrorBoundaryContainer>{element}</ErrorBoundaryContainer>;
};

// Update to React 18 (ReactDOM.Render will be deprecate)
export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    createRoot(container, {
      hydrate: true,
      hydrationOptions: { onHydrated: callback },
    }).render(element);
  };
};
