exports.components = {
  "component---src-modules-about-templates-about-jsx": () => import("./../../../src/modules/about/templates/about.jsx" /* webpackChunkName: "component---src-modules-about-templates-about-jsx" */),
  "component---src-modules-contact-templates-contact-jsx": () => import("./../../../src/modules/contact/templates/contact.jsx" /* webpackChunkName: "component---src-modules-contact-templates-contact-jsx" */),
  "component---src-modules-covid-19-templates-covid-19-jsx": () => import("./../../../src/modules/covid19/templates/covid19.jsx" /* webpackChunkName: "component---src-modules-covid-19-templates-covid-19-jsx" */),
  "component---src-modules-esg-templates-esg-jsx": () => import("./../../../src/modules/esg/templates/esg.jsx" /* webpackChunkName: "component---src-modules-esg-templates-esg-jsx" */),
  "component---src-modules-home-templates-home-jsx": () => import("./../../../src/modules/home/templates/home.jsx" /* webpackChunkName: "component---src-modules-home-templates-home-jsx" */),
  "component---src-modules-join-us-templates-join-us-jsx": () => import("./../../../src/modules/join-us/templates/join-us.jsx" /* webpackChunkName: "component---src-modules-join-us-templates-join-us-jsx" */),
  "component---src-modules-legal-templates-legal-jsx": () => import("./../../../src/modules/legal/templates/legal.jsx" /* webpackChunkName: "component---src-modules-legal-templates-legal-jsx" */),
  "component---src-modules-news-templates-news-jsx": () => import("./../../../src/modules/news/templates/news.jsx" /* webpackChunkName: "component---src-modules-news-templates-news-jsx" */),
  "component---src-modules-platform-templates-platform-jsx": () => import("./../../../src/modules/platform/templates/platform.jsx" /* webpackChunkName: "component---src-modules-platform-templates-platform-jsx" */),
  "component---src-modules-solutions-templates-solutions-jsx": () => import("./../../../src/modules/solutions/templates/solutions.jsx" /* webpackChunkName: "component---src-modules-solutions-templates-solutions-jsx" */),
  "component---src-modules-technology-templates-technology-jsx": () => import("./../../../src/modules/technology/templates/technology.jsx" /* webpackChunkName: "component---src-modules-technology-templates-technology-jsx" */),
  "component---src-modules-use-cases-nih-templates-nih-jsx": () => import("./../../../src/modules/use-cases/nih/templates/nih.jsx" /* webpackChunkName: "component---src-modules-use-cases-nih-templates-nih-jsx" */),
  "component---src-modules-use-cases-orange-and-zurich-templates-orange-and-zurich-jsx": () => import("./../../../src/modules/use-cases/orange-and-zurich/templates/orange-and-zurich.jsx" /* webpackChunkName: "component---src-modules-use-cases-orange-and-zurich-templates-orange-and-zurich-jsx" */),
  "component---src-modules-use-cases-prisa-templates-prisa-jsx": () => import("./../../../src/modules/use-cases/prisa/templates/prisa.jsx" /* webpackChunkName: "component---src-modules-use-cases-prisa-templates-prisa-jsx" */),
  "component---src-modules-use-cases-prosegur-templates-prosegur-jsx": () => import("./../../../src/modules/use-cases/prosegur/templates/prosegur.jsx" /* webpackChunkName: "component---src-modules-use-cases-prosegur-templates-prosegur-jsx" */),
  "component---src-modules-use-cases-telefonica-templates-telefonica-jsx": () => import("./../../../src/modules/use-cases/telefonica/templates/telefonica.jsx" /* webpackChunkName: "component---src-modules-use-cases-telefonica-templates-telefonica-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-eu-404-js": () => import("./../../../src/pages/eu/404.js" /* webpackChunkName: "component---src-pages-eu-404-js" */)
}

