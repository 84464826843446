/* eslint-disable consistent-return */
import React from 'react';
import getRoute from './getRoute';

export const getReflangTags = ({ page }) => {
  if (typeof page === 'undefined') return;

  const siteUrl = process.env.GATSBY_SITE_URL || 'https://www.sherpa.ai';
  const route = getRoute(page);

  if (!route) return;
  return Object.entries(route.slugs).map(val => {
    const lang = val[0];
    const path = val[1];
    const href = lang === 'en' ? `${siteUrl}${path}` : `${siteUrl}/${lang}${path}`;
    if (lang === 'en') return <link key={`${page}-${lang}`} rel='canonical' href={href} />;
    return <link key={`${page}-${lang}`} rel='alternate' hrefLang={lang} href={href} />;
  });
};

export default getReflangTags;
