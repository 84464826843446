import { Link } from 'gatsby';
import React from 'react';

function GatsbyLink({ item }) {
  const { href, name, highlight, gatsbyLink } = item;

  if (gatsbyLink) {
    return (
      <Link to={href} className={highlight ? 'button button--primary' : ''}>
        {name}
      </Link>
    );
  }

  return (
    <a href={href} target='_blank' className={highlight ? 'button button--primary' : ''} rel='noreferrer'>
      {name}
    </a>
  );
}

export default GatsbyLink;
