import React, { useState } from 'react';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import languages from '../../../langs';
import ChevronDown from '../../shared/components/svg/chevron-down';
import './languages-selector.scss';

const LanguagesSelector = ({ currentLang, currentRoute, showOnlyInDesktop = false }) => {
  const [navState, setNavState] = useState({ open: false });

  const activateSelector = () => {
    setNavState({ ...navState, open: !navState.open });
  };

  return (
    <div
      className={`dropdown${showOnlyInDesktop ? ' hidden-navbar' : ' dropdown-languages-selector'}${
        navState.open ? ' dropdown--open' : ''
      }`}
    >
      <button type='button' className='dropdown-current-lang button button--link' onClick={activateSelector}>
        <span className='icon'>
          <ChevronDown />
        </span>
        <span className='text'>{currentLang}</span>
      </button>
      <nav>
        <ul>
          {languages.langs
            .filter(lang => lang.key !== currentLang)
            .map(lang => {
              return (
                <li key={uuid()} className={currentLang === lang.key ? 'active' : ''}>
                  <Link to={`${lang.key !== 'en' ? `/${lang.key}` : ''}${currentRoute.slugs[lang.key]}`}>
                    {lang.key}
                  </Link>
                </li>
              );
            })}
        </ul>
      </nav>
    </div>
  );
};

LanguagesSelector.propTypes = {
  showOnlyInDesktop: PropTypes.bool,
  currentLang: PropTypes.string,
  currentRoute: PropTypes.shape({
    slugs: PropTypes.shape({
      es: PropTypes.string,
      en: PropTypes.string,
      eu: PropTypes.string,
    }),
  }),
};

LanguagesSelector.displayName = 'LanguageSelector';

export default LanguagesSelector;
