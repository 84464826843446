import React from 'react';

const HamburgerMenu = () => {
  return (
    <>
      <svg
        className='hamburger-menu'
        width='21'
        height='17'
        viewBox='0 0 21 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line y1='0.5' x2='21' y2='0.5' stroke='#F2F2F2' />
        <line y1='8.5' x2='21' y2='8.5' stroke='#F2F2F2' />
        <line y1='16.5' x2='21' y2='16.5' stroke='#F2F2F2' />
      </svg>
    </>
  );
};

export default HamburgerMenu;
