/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Footer from './footer/footer';
import Header from './header/header';
import Menu from './menu/menu';
import CookieConsent from '../shared/components/cookie-consent/cookie-consent';
import { getSharedData } from '../shared/data';
import '../shared/scss/styles.scss';
import './layout.scss';

const Layout = ({ children, pageContext, page = 'default', pageClass = '' }) => {
  const headerRef = useRef();
  const [menuState, setMenuState] = useState({ open: false });

  const toggleMenu = () => {
    setMenuState(prevState => ({
      open: !prevState.open,
    }));
  };

  const pageClassName = pageClass ? ` ${pageClass}` : '';

  const sharedData = getSharedData(pageContext.lang.key);

  return (
    <>
      <div className={`site-wrapper site-wrapper--${page}${pageClassName}`}>
        <Header
          ref={headerRef}
          menuOpened={menuState.open}
          currentRoute={pageContext.route}
          currentLang={pageContext.lang.key}
          onButtonMenuClicked={() => {
            toggleMenu();
          }}
        />
        <Menu open={menuState.open} currentRoute={pageContext.route} currentLang={pageContext.lang.key} />
        <main>{children}</main>
      </div>
      <Footer currentLang={pageContext.lang.key} />
      <CookieConsent content={sharedData.cookiesBanner} />
    </>
  );
};
Layout.propTypes = {
  page: PropTypes.string,
  pageContext: PropTypes.shape({
    route: PropTypes.shape({
      slugs: PropTypes.shape({
        es: PropTypes.string,
        en: PropTypes.string,
        eu: PropTypes.string,
      }),
    }),
    lang: PropTypes.shape({
      key: PropTypes.string,
    }),
  }),
};
Layout.displayName = 'Layout';

export default Layout;
