import React from 'react';

const BrandSVG = () => {
  return (
    <>
      <svg
        className='brand'
        width='113'
        height='29'
        viewBox='0 0 113 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M85.7311 13.0181V18.262C85.7311 20.1767 84.1896 22.1087 82.319 22.1087H82.2843C82.1631 22.1087 82.0592 22.0052 82.0592 21.8672V20.1077C81.0373 21.4187 79.5131 22.1087 77.6079 22.1087C74.9752 22.1087 72.152 20.6597 72.152 17.4858C72.152 12.1729 78.6298 12.1729 81.3837 12.1729H82.0418V12.0176C82.0418 10.4479 80.8987 9.51645 78.9935 9.51645C78.3007 9.51645 77.6252 9.65445 76.9843 9.91319C75.6853 10.3617 74.1958 10.0857 73.1566 9.06796L73.1219 9.03346C73.0873 8.99896 73.0526 8.94721 73.0526 8.89547C73.0526 8.84372 73.07 8.77472 73.1219 8.74022C73.1392 8.72297 73.1566 8.70572 73.1912 8.67122C74.3517 7.49825 75.9278 6.72201 77.7464 6.44602H77.7637C78.2833 6.35977 78.8376 6.32527 79.3918 6.32527C85.6445 6.29077 85.7311 10.9309 85.7311 13.0181ZM81.834 15.7781V15.1916H81.3144C77.8503 15.1916 76.1703 15.8471 76.1703 17.2098C76.1703 18.3655 77.3481 18.883 78.4392 18.883C80.6562 18.9002 81.834 17.8135 81.834 15.7781Z'
          fill='#F2F2F2'
        />
        <path
          d='M105.233 13.0181V18.262C105.233 20.1767 103.692 22.1087 101.821 22.1087H101.787C101.665 22.1087 101.561 22.0052 101.561 21.8672V20.1077C100.539 21.4187 99.0153 22.1087 97.1101 22.1087C94.4774 22.1087 91.6542 20.6597 91.6542 17.4858C91.6542 12.1729 98.132 12.1729 100.886 12.1729H101.544V12.0176C101.544 10.4479 100.401 9.51645 98.4957 9.51645C97.8029 9.51645 97.1274 9.65445 96.4865 9.91319C95.1875 10.3617 93.698 10.0857 92.6587 9.06796L92.6241 9.03346C92.5895 8.99896 92.5548 8.94721 92.5548 8.89547C92.5548 8.84372 92.5721 8.77472 92.6241 8.74022C92.6414 8.72297 92.6587 8.70572 92.6934 8.67122C93.8538 7.49825 95.43 6.72201 97.2486 6.44602H97.2659C97.7855 6.35977 98.3398 6.32527 98.894 6.32527C105.147 6.29077 105.233 10.9309 105.233 13.0181ZM101.336 15.7781V15.1916H100.817C97.3525 15.1916 95.6725 15.8471 95.6725 17.2098C95.6725 18.3655 96.8503 18.883 97.9414 18.883C100.158 18.9002 101.336 17.8135 101.336 15.7781Z'
          fill='#2481C4'
        />
        <path
          d='M63.3979 18.2698C65.909 18.2698 67.2079 16.2443 67.2079 14.236C67.2079 12.2278 65.8917 10.2022 63.3979 10.2022C60.8868 10.2022 59.5879 12.2278 59.5879 14.236C59.5879 16.227 60.9041 18.2698 63.3979 18.2698ZM64.0906 6.51462C68.2123 6.51462 71.1044 9.68282 71.1044 14.2187C71.1044 18.6853 68.2123 21.9228 64.2292 21.9228C62.3588 21.9228 60.8348 21.2822 59.6918 20.0357C59.6918 20.0357 59.6745 26.1471 59.6745 26.2163C59.6745 27.3243 58.8259 28.2419 57.7522 28.2419C56.6785 28.2419 55.7953 27.3416 55.7953 26.2163C55.7953 26.2163 55.7953 26.2163 55.7953 26.199V9.76938C55.7953 7.81306 57.3539 6.13375 59.2416 6.13375H59.2762C59.3974 6.13375 59.5013 6.23762 59.5013 6.35881V8.59213C60.5404 7.25906 62.1683 6.51462 64.0906 6.51462Z'
          fill='#F2F2F2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.0496 6.96351C54.1539 6.99801 54.2235 7.08425 54.2235 7.18775V10.6204C54.2235 10.6894 54.1887 10.7584 54.1366 10.7929C54.0844 10.8447 54.0149 10.8619 53.928 10.8274C53.3022 10.6722 52.7807 10.5687 52.2592 10.5687C48.6783 10.5687 48.6261 13.8806 48.6261 13.9151C48.6261 13.9151 48.6435 20.0042 48.6435 20.0905C48.6435 21.1944 47.7396 22.1087 46.6097 22.1087C45.6884 22.1087 44.5758 21.4704 44.5758 20.1077C44.5585 20.0732 44.5758 10.0339 44.5758 10.0339C44.5758 7.98123 46.2794 6.29077 48.3654 6.29077H48.4001C48.5218 6.29077 48.6261 6.39427 48.6261 6.51502V8.80922C49.53 7.49824 50.9902 6.73926 52.7111 6.73926C53.2153 6.75651 53.6846 6.86001 54.0496 6.96351Z'
          fill='#F2F2F2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M111.44 13.9151C111.44 13.9151 111.457 20.0042 111.457 20.0905C111.457 21.1944 110.574 22.1087 109.47 22.1087C108.57 22.1087 107.483 21.4704 107.483 20.1077C107.466 20.0732 107.483 10.0339 107.483 10.0339C107.483 7.98123 109.147 6.29077 111.186 6.29077H111.22C111.338 6.29077 111.44 6.39427 111.44 6.51502C111.44 11.4254 111.44 13.8921 111.44 13.9151Z'
          fill='#2481C4'
        />
        <path
          d='M32.4052 12.3494H39.3772C39.2561 10.4674 37.9759 9.34507 35.9171 9.34507C33.9968 9.34507 32.7339 10.4328 32.4052 12.3494ZM36.4188 6.13356C40.7958 6.13356 43.512 9.27601 43.512 14.3177V14.335C43.512 15.0084 42.9584 15.5609 42.2837 15.5609H32.3879C32.6993 17.3566 34.1352 18.5652 36.0382 18.5652C36.7821 18.5652 37.4396 18.4271 38.0451 18.1163H38.0624C39.3253 17.3566 40.9342 17.3738 42.1626 18.1853L42.5605 18.4789L42.7162 18.5997C42.7681 18.6343 42.8027 18.7033 42.8027 18.7551C42.8027 18.7724 42.8027 18.7724 42.8027 18.7724C42.8027 18.8415 42.7854 18.9105 42.7335 18.9623L42.6989 18.9796C41.0899 20.9307 38.962 21.9321 36.3842 21.9321C31.6439 21.9321 28.2012 18.5997 28.2012 14.0242C28.2012 9.44867 31.6785 6.13356 36.4188 6.13356Z'
          fill='#F2F2F2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.7065 -1.52588e-05H17.7409C17.8611 -1.52588e-05 17.9642 0.10386 17.9642 0.225048V8.17151C18.6684 7.202 19.9395 6.31906 21.8117 6.31906C25.0751 6.31906 27.016 8.50045 27.016 12.1534V20.0999C27.016 21.2079 26.1572 22.0908 25.1095 22.0908H25.0751C24.0274 22.0908 23.1686 21.2079 23.1686 20.0999V13.9366C23.1686 11.9803 22.8938 9.98932 20.8841 9.98932C18.1875 9.98932 17.9814 12.5862 17.9814 13.3826V20.1172C17.9814 21.2079 17.1226 22.1081 16.0748 22.1081H16.0405C14.9927 22.1081 14.1339 21.2252 14.1339 20.1172V3.16818C14.1167 1.31574 16.0576 -1.52588e-05 17.7065 -1.52588e-05Z'
          fill='#F2F2F2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.8788 14.5016C11.5016 15.1398 11.9168 16.0541 11.8822 17.3823C11.7957 20.7459 8.80272 22.1777 5.86166 22.1087C3.97592 22.0569 1.36357 21.0909 0.290948 20.0215C-0.124261 19.6075 -0.08966 18.9175 0.360149 18.5207L2.07288 17.0373C2.12479 17.0028 2.19399 16.9683 2.24589 16.9855C2.31509 17.0028 2.36699 17.0373 2.40159 17.089C3.0417 18.0378 4.94474 18.7277 6.03466 18.7622C6.65748 18.7795 7.8685 18.538 7.8858 17.5203C7.9031 16.7095 6.95158 16.4163 5.37725 16.0368C4.04512 15.6918 2.47079 15.2778 1.38087 14.1393C1.38087 14.1393 1.12136 13.8978 1.01756 13.7771C0.37745 13.0699 -0.0204586 12.0866 0.117944 10.6722C0.446651 7.25675 3.59531 6.08377 6.57098 6.34252C8.02421 6.46326 8.99303 6.80826 9.46013 7.08425C10.6712 7.77423 11.6054 9.98219 10.0829 11.5174L10.0483 11.5347C9.99645 11.5692 9.92724 11.5864 9.85804 11.5692C9.78884 11.5519 9.71964 11.5174 9.70234 11.4484C9.04493 9.65444 6.64018 9.56819 6.12117 9.55094C5.48105 9.5337 4.23543 9.79244 4.14893 10.8274C4.06242 11.6554 5.01394 12.0176 6.58828 12.5351C6.67478 12.5696 6.84778 12.6041 6.84778 12.6041C8.23181 12.9491 9.87534 13.3631 10.8788 14.5016Z'
          fill='#F2F2F2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M88.8053 18.0515C89.8787 18.0515 90.7476 18.9205 90.7476 20.0109C90.7476 21.1014 89.8787 21.9703 88.8053 21.9703C87.7319 21.9703 86.8629 21.1014 86.8629 20.0109C86.8629 18.9205 87.7319 18.0515 88.8053 18.0515Z'
          fill='#2481C4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M110.195 0.436638C111.268 0.436638 112.137 1.30559 112.137 2.39603C112.137 3.48648 111.268 4.35543 110.195 4.35543C109.121 4.35543 108.252 3.48648 108.252 2.39603C108.252 1.30559 109.121 0.436638 110.195 0.436638Z'
          fill='#2481C4'
        />
      </svg>
    </>
  );
};

export default BrandSVG;
