import React from 'react';

const BrandSVGDark = () => {
  return (
    <svg width='113' height='31' viewBox='0 0 113 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M85.7311 14.2473V19.7295C85.7311 21.7312 84.1896 23.751 82.319 23.751H82.2843C82.1631 23.751 82.0592 23.6428 82.0592 23.4985V21.6591C81.0373 23.0296 79.5131 23.751 77.6079 23.751C74.9752 23.751 72.152 22.2362 72.152 18.918C72.152 13.3636 78.6298 13.3636 81.3837 13.3636H82.0418V13.2013C82.0418 11.5602 80.8987 10.5864 78.9935 10.5864C78.3007 10.5864 77.6252 10.7307 76.9843 11.0012C75.6853 11.4701 74.1958 11.1815 73.1566 10.1175L73.1219 10.0815C73.0873 10.0454 73.0526 9.99131 73.0526 9.93721C73.0526 9.88311 73.07 9.81098 73.1219 9.77491C73.1392 9.75688 73.1566 9.73884 73.1912 9.70277C74.3517 8.47649 75.9278 7.66497 77.7464 7.37644H77.7637C78.2834 7.28627 78.8376 7.2502 79.3918 7.2502C85.6445 7.21413 85.7311 12.0652 85.7311 14.2473ZM81.834 17.1326V16.5195H81.3144C77.8503 16.5195 76.1703 17.2048 76.1703 18.6294C76.1703 19.8377 77.3481 20.3787 78.4392 20.3787C80.6562 20.3967 81.834 19.2606 81.834 17.1326Z'
        fill='#50545B'
      />
      <path
        d='M105.233 14.2473V19.7295C105.233 21.7312 103.692 23.751 101.821 23.751H101.787C101.665 23.751 101.561 23.6428 101.561 23.4985V21.6591C100.539 23.0296 99.0153 23.751 97.1101 23.751C94.4774 23.751 91.6542 22.2362 91.6542 18.918C91.6542 13.3636 98.132 13.3636 100.886 13.3636H101.544V13.2013C101.544 11.5602 100.401 10.5864 98.4957 10.5864C97.8029 10.5864 97.1274 10.7307 96.4865 11.0012C95.1875 11.4701 93.698 11.1815 92.6588 10.1175L92.6241 10.0815C92.5895 10.0454 92.5548 9.99131 92.5548 9.93721C92.5548 9.88311 92.5721 9.81098 92.6241 9.77491C92.6414 9.75688 92.6588 9.73884 92.6934 9.70277C93.8538 8.47649 95.43 7.66497 97.2486 7.37644H97.2659C97.7855 7.28627 98.3398 7.2502 98.894 7.2502C105.147 7.21413 105.233 12.0652 105.233 14.2473ZM101.336 17.1326V16.5195H100.817C97.3525 16.5195 95.6725 17.2048 95.6725 18.6294C95.6725 19.8377 96.8503 20.3787 97.9414 20.3787C100.158 20.3967 101.336 19.2606 101.336 17.1326Z'
        fill='#50545B'
      />
      <path
        d='M63.3979 19.7366C65.909 19.7366 67.2079 17.6191 67.2079 15.5196C67.2079 13.4201 65.8917 11.3026 63.3979 11.3026C60.8868 11.3026 59.5879 13.4201 59.5879 15.5196C59.5879 17.601 60.9041 19.7366 63.3979 19.7366ZM64.0906 7.44749C68.2123 7.44749 71.1044 10.7596 71.1044 15.5015C71.1044 20.171 68.2123 23.5555 64.2292 23.5555C62.3588 23.5555 60.8348 22.8859 59.6918 21.5827C59.6918 21.5827 59.6745 27.9717 59.6745 28.044C59.6745 29.2024 58.8259 30.1616 57.7522 30.1616C56.6785 30.1616 55.7953 29.2205 55.7953 28.044C55.7953 28.044 55.7953 28.044 55.7953 28.0259V10.8501C55.7953 8.80491 57.3539 7.04932 59.2416 7.04932H59.2762C59.3974 7.04932 59.5013 7.15791 59.5013 7.2846V9.61936C60.5404 8.22575 62.1683 7.44749 64.0906 7.44749Z'
        fill='#50545B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.0496 7.91672C54.1539 7.95279 54.2235 8.04296 54.2235 8.15117V11.74C54.2235 11.8122 54.1887 11.8843 54.1366 11.9204C54.0844 11.9745 54.0149 11.9925 53.928 11.9564C53.3022 11.7941 52.7807 11.6859 52.2592 11.6859C48.6783 11.6859 48.6261 15.1485 48.6261 15.1846C48.6261 15.1846 48.6435 21.5508 48.6435 21.6409C48.6435 22.7952 47.7396 23.751 46.6097 23.751C45.6884 23.751 44.5758 23.0837 44.5758 21.659C44.5585 21.6229 44.5758 11.1269 44.5758 11.1269C44.5758 8.98076 46.2794 7.21338 48.3654 7.21338H48.4001C48.5218 7.21338 48.6261 7.32159 48.6261 7.44783V9.84641C49.53 8.47579 50.9902 7.68227 52.7111 7.68227C53.2153 7.70031 53.6846 7.80852 54.0496 7.91672Z'
        fill='#50545B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.44 15.1846C111.44 15.1846 111.457 21.5508 111.457 21.6409C111.457 22.7952 110.574 23.751 109.47 23.751C108.57 23.751 107.483 23.0837 107.483 21.659C107.466 21.6229 107.483 11.1269 107.483 11.1269C107.483 8.98076 109.147 7.21338 111.186 7.21338H111.22C111.338 7.21338 111.44 7.32159 111.44 7.44783C111.44 12.5816 111.44 15.1606 111.44 15.1846Z'
        fill='#50545B'
      />
      <path
        d='M32.4052 13.5478H39.3772C39.2561 11.5802 37.9759 10.4069 35.9171 10.4069C33.9968 10.4069 32.7339 11.5441 32.4052 13.5478ZM36.4188 7.04932C40.7958 7.04932 43.512 10.3347 43.512 15.6057V15.6238C43.512 16.3278 42.9584 16.9054 42.2837 16.9054H32.3879C32.6993 18.7828 34.1352 20.0464 36.0382 20.0464C36.7821 20.0464 37.4396 19.902 38.0451 19.577H38.0624C39.3253 18.7828 40.9342 18.8008 42.1626 19.6492L42.5605 19.9561L42.7162 20.0825C42.7681 20.1186 42.8027 20.1908 42.8027 20.2449C42.8027 20.263 42.8027 20.263 42.8027 20.263C42.8027 20.3352 42.7854 20.4074 42.7335 20.4616L42.6989 20.4796C41.0899 22.5194 38.962 23.5664 36.3842 23.5664C31.6439 23.5664 28.2012 20.0825 28.2012 15.2988C28.2012 10.5152 31.6785 7.04932 36.4188 7.04932Z'
        fill='#50545B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.7065 0.635742H17.7409C17.8611 0.635742 17.9642 0.74434 17.9642 0.871038V9.17879C18.6684 8.16521 19.9395 7.24212 21.8117 7.24212C25.0751 7.24212 27.016 9.52268 27.016 13.3417V21.6495C27.016 22.8078 26.1572 23.7309 25.1095 23.7309H25.0751C24.0274 23.7309 23.1686 22.8078 23.1686 21.6495V15.206C23.1686 13.1607 22.8938 11.0793 20.8841 11.0793C18.1875 11.0793 17.9814 13.7942 17.9814 14.6268V21.6676C17.9814 22.8078 17.1226 23.749 16.0748 23.749H16.0405C14.9927 23.749 14.1339 22.8259 14.1339 21.6676V3.94798C14.1167 2.01132 16.0576 0.635742 17.7065 0.635742Z'
        fill='#50545B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8788 15.7975C11.5016 16.4648 11.9168 17.4207 11.8822 18.8094C11.7957 22.3262 8.80272 23.8231 5.86166 23.7509C3.97592 23.6968 1.36357 22.6869 0.290948 21.5687C-0.124261 21.1359 -0.08966 20.4145 0.360149 19.9997L2.07288 18.4487C2.12478 18.4126 2.19399 18.3765 2.24589 18.3946C2.31509 18.4126 2.36699 18.4487 2.40159 18.5028C3.0417 19.4947 4.94474 20.2161 6.03466 20.2522C6.65748 20.2702 7.8685 20.0177 7.8858 18.9536C7.9031 18.106 6.95158 17.7994 5.37725 17.4026C4.04512 17.0419 2.47079 16.6091 1.38087 15.4188C1.38087 15.4188 1.12136 15.1663 1.01756 15.0401C0.377449 14.3006 -0.0204586 13.2726 0.117944 11.7938C0.446651 8.22287 3.59531 6.9965 6.57097 7.26702C8.0242 7.39327 8.99302 7.75396 9.46013 8.04252C10.6712 8.76392 11.6054 11.0724 10.0829 12.6775L10.0483 12.6955C9.99644 12.7316 9.92724 12.7496 9.85804 12.7316C9.78884 12.7136 9.71964 12.6775 9.70234 12.6054C9.04493 10.7297 6.64018 10.6396 6.12117 10.6215C5.48105 10.6035 4.23543 10.874 4.14893 11.9561C4.06242 12.8218 5.01394 13.2005 6.58828 13.7416C6.67478 13.7776 6.84778 13.8137 6.84778 13.8137C8.23181 14.1744 9.87534 14.6072 10.8788 15.7975Z'
        fill='#50545B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M88.8053 19.5078C89.8787 19.5078 90.7476 20.4164 90.7476 21.5566C90.7476 22.6969 89.8787 23.6055 88.8053 23.6055C87.7319 23.6055 86.8629 22.6969 86.8629 21.5566C86.8629 20.4164 87.7319 19.5078 88.8053 19.5078Z'
        fill='#50545B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M110.195 1.09277C111.268 1.09277 112.137 2.00106 112.137 3.14087C112.137 4.28068 111.268 5.18896 110.195 5.18896C109.121 5.18896 108.252 4.28068 108.252 3.14087C108.252 2.00106 109.121 1.09277 110.195 1.09277Z'
        fill='#50545B'
      />
    </svg>
  );
};

export default BrandSVGDark;
