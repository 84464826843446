import React from 'react';

const ChevronDown = () => {
  return (
    <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 1L7 7L13 1' stroke='#F2F2F2' />
    </svg>
  );
};

export default ChevronDown;
