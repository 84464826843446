module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sherpa.ai","short_name":"Sherpa.ai","lang":"en","display":"standalone","icon":"src/images/sherpa-favicon.svg","start_url":"/","background_color":"#f2f2f2","theme_color":"#f2f2f2","localize":[{"start_url":"/es/","lang":"es","name":"ES Sherpa.ai","short_name":"Sherpa.ai"},{"start_url":"/eu/","lang":"eu","name":"EU Sherpa.ai","short_name":"Sherpa.ai"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"54830563eaeccab0bf639f07d9d48857"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HYKZHYD4S5"]},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
