import React from 'react';

const CloseMenu = () => {
  return (
    <svg
      className='close-menu'
      width='18'
      height='17'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line x1='0.646447' y1='18.6464' x2='18.6464' y2='0.646446' stroke='#F2F2F2' />
      <line x1='1.35355' y1='0.646447' x2='19.3536' y2='18.6464' stroke='#F2F2F2' />
    </svg>
  );
};
export default CloseMenu;
