/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';

import { getSharedData } from '../modules/shared/data';
import Layout from '../modules/layout/layout';

import Metatags from '../modules/shared/components/metatags/metatags';
import ErrorTemplateLayout from '../modules/shared/components/error-template-layout/ErrorTemplateLayout';

import './404.scss';

const NotFoundPage = () => {
  const pageContext = {
    lang: { key: 'en', locale: 'en' },
    route: {
      id: '404',
      slugs: {
        es: '/es/404/',
        en: '/404/',
        eu: '/eu/404',
      },
    },
  };
  const sharedData = getSharedData(pageContext.lang.key);

  return (
    <Layout pageContext={pageContext} pageClass='page-404-full-wrapper'>
      <Metatags page={pageContext.route.id} lang={pageContext.lang.key} content={sharedData.metatags} />
      <ErrorTemplateLayout
        routeToNavigate='/'
        error='404'
        subtitle='Page not found.'
        dialog='We are sorry but the page you are looking for doesnt exist.'
        buttonDialog='Return to homepage'
      />
    </Layout>
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
