import React from 'react';
import { Helmet } from 'react-helmet';
import { getReflangTags } from '../../hooks/getReflangTags';
import getRoute from '../../hooks/getRoute';
import sherpaLogo from '../../../../../static/images/sherpa_logo_blue.png';

function Metatags({ content, lang, page, isModal = false }) {
  const metatags = isModal ? content : content[page];

  if (isModal && content) {
    return (
      <Helmet defaultTitle='Video' htmlAttributes={{ lang }}>
        <title>{metatags?.title}</title>
        <meta name='title' content={metatags?.title} />
        <meta name='description' content={metatags?.description} />
      </Helmet>
    );
  }

  if (page === '404') {
    return (
      <Helmet htmlAttributes={{ lang }}>
        <title>{metatags?.title}</title>
        <meta name='robots' content='noindex, follow' />
      </Helmet>
    );
  }

  const siteUrl = process.env.GATSBY_SITE_URL || 'https://www.sherpa.ai';
  const route = getRoute(page);
  const path = route?.slugs[lang];
  const url = lang === 'en' ? `${siteUrl}${path}` : `${siteUrl}/${lang}${path}`;
  const reflangTags = getReflangTags({ page });
  const imageUrl = `${siteUrl}${sherpaLogo}`;

  return (
    <Helmet defaultTitle='Sherpa.ai' htmlAttributes={{ lang }}>
      <title>{metatags?.title}</title>
      <meta name='title' content={metatags?.title} />
      <meta name='description' content={metatags?.description} />
      {reflangTags}
      {/* Open Graph: Meta */}
      <meta property='og:title' content={metatags?.openGraph.title} />
      <meta property='og:description' content={metatags?.openGraph.description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={imageUrl} />
      <meta property='og:type' content={metatags?.openGraph.type} />
      {/* Open Graph: Twitter */}
      <meta property='twitter:title' content={metatags?.twitter.title} />
      <meta property='twitter:description' content={metatags?.twitter.description} />
      <meta property='twitter:url' content={url} />
      <meta property='twitter:image' content={imageUrl} />
      <meta property='twitter:card' content={metatags?.twitter.card} />
    </Helmet>
  );
}

export default Metatags;
