import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './ErrorTemplateLayout.scss';

const core = 'error-template-layout';

const ErrorTemplateLayout = ({ error, subtitle, dialog, buttonDialog, routeToNavigate }) => {
  return (
    <section className={`${core}`}>
      <article className={`${core}__header`}>
        <h2>Error</h2>
        <h1>{error}</h1>
      </article>
      <article className={`${core}__body`}>
        <p>
          <strong>{subtitle}</strong>
        </p>
        <small>{dialog}</small>
      </article>
      {buttonDialog && (
        <Link to={routeToNavigate} className={`${core}__return-action`}>
          {buttonDialog}
        </Link>
      )}
    </section>
  );
};

ErrorTemplateLayout.propTypes = {
  routeToNavigate: PropTypes.string,
  error: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  dialog: PropTypes.string,
  buttonDialog: PropTypes.string,
};
ErrorTemplateLayout.displayName = 'ErrorTemplateLayout';

export default ErrorTemplateLayout;
