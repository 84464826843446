/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
import React from 'react';
import uuid from 'react-uuid';
import './cookie-consent-table.scss';

const CookieConsentTable = ({
  content,
  fields,
  moreInfo = null,
  terms,
  setTerms,
  expanded = false,
  configure = null,
}) => {
  return (
    <div
      className={`table-cookie-consent-terms${
        expanded ? ' table-cookie-consent-terms--expanded' : ' table-cookie-consent-terms--default'
      }`}
    >
      {!expanded &&
        content.map((table, tableIndex) => {
          return (
            <table key={`cookie-terms-table-${tableIndex}`}>
              <thead>
                <tr>
                  <th>
                    <div
                      className={`form-field form-field-checkbox${
                        tableIndex === 0 ? ' form-field-checkbox--disabled' : ''
                      }`}
                    >
                      <label htmlFor='navigation'>
                        <input
                          disabled={tableIndex === 0}
                          checked={terms[Object.keys(terms)[tableIndex]]}
                          name='navigation'
                          type='checkbox'
                          onChange={() => {
                            if (tableIndex === 0) return;
                            setTerms({ ...terms, thirdPartCookies: !terms.thirdPartCookies });
                          }}
                        />
                        <span className='marker marker--secondary' />
                        <span>
                          <strong>{table.title}</strong>
                        </span>
                      </label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {table.excerpt}{' '}
                    <button className='button button--link' onClick={() => configure()}>
                      {moreInfo.title}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      {expanded &&
        content.map((table, tableIndex) => {
          return (
            <table key={`cookie-terms-table-${uuid()}`}>
              <thead>
                <tr>
                  <th colSpan='5'>
                    <div
                      className={`form-field form-field-checkbox${
                        tableIndex === 0 ? ' form-field-checkbox--disabled' : ''
                      }`}
                    >
                      <label htmlFor='navigation'>
                        <input
                          disabled={tableIndex === 0}
                          checked={terms[Object.keys(terms)[tableIndex]]}
                          name='navigation'
                          type='checkbox'
                          onChange={() => {
                            if (tableIndex === 0) return;
                            setTerms({ ...terms, thirdPartCookies: !terms.thirdPartCookies });
                          }}
                        />
                        <span className='marker marker--secondary' />
                        <span>
                          <strong>{table.title}</strong>
                        </span>
                      </label>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td colSpan='5' dangerouslySetInnerHTML={{ __html: table.excerpt }} />
                </tr>
                <tr>
                  {Object.keys(fields)
                    .filter(item => item !== 'title' && item !== 'excerpt')
                    .map(field => {
                      return <th key={`table-${tableIndex}-head-field-${uuid()}`}>{fields[field]}</th>;
                    })}
                </tr>
              </thead>
              <tbody>
                {table.cookies.map(row => {
                  return (
                    <tr key={`cookie-terms-table-${tableIndex}-row-${uuid()}`}>
                      {Object.keys(row)
                        .filter(item => item !== 'title' && item !== 'excerpt')
                        .map(item => {
                          return (
                            <td key={`table-${tableIndex}-body-field-${uuid()}`} data-label={fields[item]}>
                              {row[item]}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      {/* {moreInfo &&
                <div className="cookies-more-info">
                    <p className="title h4">{moreInfo.title}</p>
                    <p dangerouslySetInnerHTML={{ __html: moreInfo.description }}></p>
                </div>
            } */}
    </div>
  );
};

export default CookieConsentTable;
