import React from 'react';

const BackIcon = () => {
  return (
    <svg width='29' height='26' viewBox='0 0 29 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.66602 13H28.3327' stroke='#0C0E11' />
      <path d='M14.166 0.500003L1.66602 13L14.166 25.5' stroke='#0C0E11' />
    </svg>
  );
};

export default BackIcon;
