import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import DropdownNavMain from '../../shared/components/dropdown-nav-main/dropdown-nav-main';
import GatsbyLink from '../gatsby-link/GatsbyLink';
import './nav-main.scss';

const useCasesMenuItems = {
  en: [
    { href: '/use-cases/artificial-intelligence-nih/', name: 'NIH', highlight: false, gatsbyLink: true },
    { href: '/use-cases/artificial-intelligence-telefonica/', name: 'Telefónica', highlight: false, gatsbyLink: true },
    {
      href: '/use-cases/artificial-intelligence-orange-zurich/',
      name: 'Orange & Zurich',
      highlight: false,
      gatsbyLink: true,
    },
    { href: '/use-cases/artificial-intelligence-prosegur/', name: 'Prosegur', highlight: false, gatsbyLink: true },
    { href: '/use-cases/artificial-intelligence-covid19/', name: 'COVID-19', highlight: false, gatsbyLink: true },
  ],
  es: [
    { href: '/es/casos-exito/inteligencia-artificial-nih/', name: 'NIH', highlight: false, gatsbyLink: true },
    {
      href: '/es/casos-exito/inteligencia-artificial-telefonica/',
      name: 'Telefónica',
      highlight: false,
      gatsbyLink: true,
    },
    {
      href: '/es/casos-exito/inteligencia-artificial-orange-zurich/',
      name: 'Orange & Zurich',
      highlight: false,
      gatsbyLink: true,
    },
    { href: '/es/casos-exito/inteligencia-artificial-prosegur/', name: 'Prosegur', highlight: false, gatsbyLink: true },
    { href: '/es/casos-exito/inteligencia-artificial-covid19/', name: 'COVID-19', highlight: false, gatsbyLink: true },
  ],
  eu: [
    { href: '/eu/use-cases/artificial-intelligence-nih/', name: 'NIH', highlight: false, gatsbyLink: true },
    {
      href: '/eu/use-cases/artificial-intelligence-telefonica/',
      name: 'Telefónica',
      highlight: false,
      gatsbyLink: true,
    },
    {
      href: '/eu/use-cases/artificial-intelligence-orange-zurich/',
      name: 'Orange & Zurich',
      highlight: false,
      gatsbyLink: true,
    },
    { href: '/eu/use-cases/artificial-intelligence-prosegur/', name: 'Prosegur', highlight: false, gatsbyLink: true },
    { href: '/eu/use-cases/artificial-intelligence-covid19/', name: 'COVID-19', highlight: false, gatsbyLink: true },
  ],
};

const companyMenuItems = {
  en: [
    { href: '/about/', name: 'About', highlight: false, gatsbyLink: true },
    { href: '/news/', name: 'News', highlight: false, gatsbyLink: true },
    { href: '/careers/', name: 'Careers', highlight: false, gatsbyLink: true },
    { href: 'https://blog.sherpa.ai/', name: 'Blog', highlight: false, gatsbyLink: false },
    { href: 'https://developers.sherpa.ai/', name: 'Developers', highlight: false, gatsbyLink: false },
  ],
  es: [
    { href: '/es/sobre-nosotros/', name: 'Sobre Sherpa.ai', highlight: false, gatsbyLink: true },
    { href: '/es/noticias/', name: 'Noticias', highlight: false, gatsbyLink: true },
    { href: '/es/buscamos-talento/', name: 'Trabaja con nosotros', highlight: false, gatsbyLink: true },
    { href: 'https://blog.sherpa.ai/', name: 'Blog', highlight: false, gatsbyLink: false },
    { href: 'https://developers.sherpa.ai/', name: 'Desarrolladores', highlight: false, gatsbyLink: false },
  ],
  eu: [
    { href: '/eu/about/', name: 'About', highlight: false, gatsbyLink: true },
    { href: '/eu/news/', name: 'News', highlight: false, gatsbyLink: true },
    { href: '/eu/careers/', name: 'Careers', highlight: false, gatsbyLink: true },
    { href: 'https://blog.sherpa.ai/', name: 'Bloga', highlight: false, gatsbyLink: false },
    { href: 'https://developers.sherpa.ai/', name: 'Garatzaileak', highlight: false, gatsbyLink: false },
  ],
};

const menuItems = {
  en: [
    { href: '/', name: 'Home', highlight: false, gatsbyLink: true },
    { href: '/esg-privacy-technologies/', name: 'Solutions', highlight: false, gatsbyLink: true },
    { href: '/esg-compliance/', name: 'ESG', highlight: false, gatsbyLink: true },
    {
      href: '/use-cases/',
      name: 'Use Cases',
      highlight: false,
      dropdownLinks: useCasesMenuItems.en,
    },
    { href: '/our-federated-learning/', name: 'Technology', highlight: false, gatsbyLink: true },
    { href: '/federated-learning-platform/', name: 'Platform', highlight: false, gatsbyLink: true },
    { href: '/about/', name: 'Company', highlight: false, dropdownLinks: companyMenuItems.en },
    { href: 'https://platform.sherpa.ai/welcome', name: 'Access to Platform', highlight: true, gatsbyLink: false },
  ],
  es: [
    { href: '/es/', name: 'Home', highlight: false, gatsbyLink: true },
    {
      href: '/es/tecnologias-privacidad-esg/',
      name: 'Soluciones',
      highlight: false,
      gatsbyLink: true,
    },
    { href: '/es/cumplimiento-normativa-esg/', name: 'ESG', highlight: false, gatsbyLink: true },
    {
      href: '/es/casos-exito/',
      name: 'Casos de Uso',
      highlight: false,
      dropdownLinks: useCasesMenuItems.es,
    },
    { href: '/es/nuestro-aprendizaje-federado/', name: 'Tecnología', highlight: false, gatsbyLink: true },
    { href: '/es/plataforma-aprendizaje-federado/', name: 'Plataforma', highlight: false, gatsbyLink: true },
    {
      href: '/es/sobre-nosotros/',
      name: 'Compañía',
      highlight: false,
      dropdownLinks: companyMenuItems.es,
    },
    { href: 'https://flp.sherpa.ai/welcome', name: 'Acceso a la Plataforma', highlight: true, gatsbyLink: false },
  ],
  eu: [
    { href: '/eu/', name: 'Home', highlight: false, gatsbyLink: true },
    { href: '/eu/esg-privacy-technologies/', name: 'Soluzioak', highlight: false, gatsbyLink: true },
    { href: '/eu/esg-compliance/', name: 'ESG', highlight: false, gatsbyLink: true },
    {
      href: '/eu/use-cases/',
      name: 'Use Cases',
      highlight: false,
      dropdownLinks: useCasesMenuItems.eu,
    },
    { href: '/eu/our-federated-learning/', name: 'Teknologia', highlight: false, gatsbyLink: true },
    { href: '/eu/federated-learning-platform/', name: 'Platform', highlight: false, gatsbyLink: true },
    {
      href: '/eu/about/',
      name: 'Compañía',
      highlight: false,
      dropdownLinks: companyMenuItems.eu,
    },
    { href: 'https://flp.sherpa.ai/welcome', name: 'Access to Platform', highlight: true, gatsbyLink: false },
  ],
};

const NavMain = ({ showOnlyInDesktop = false, currentLang = 'en' }) => {
  return (
    <nav className={`nav-main-menu${showOnlyInDesktop ? ' hidden-navbar' : ''}`}>
      <ul>
        {menuItems[currentLang].map(item => {
          const { highlight, name, dropdownLinks } = item;

          if (dropdownLinks) {
            return <DropdownNavMain key={`nav_${uuid()}`} name={name} menuItems={dropdownLinks} />;
          }
          return (
            <li key={`nav_${uuid()}`} className={highlight ? 'highlighted' : ''}>
              <GatsbyLink item={item} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

NavMain.propTypes = {
  showOnlyInDesktop: PropTypes.bool,
  currentLang: PropTypes.string,
};

NavMain.displayName = 'NavMain';

export default NavMain;
